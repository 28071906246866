import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import "./styles.css";
import bbc from './utils/bbc.json';
import Dog from './assets/images/dog.png';

function App() {
  const [mintAmount, setCount] = useState(() => { return 0; });
  const [currentAccount, setCurrentAccount] = useState("");
  const [VARIABLES, setCurrentGas] = useState({GAS: 0});

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum obnject", ethereum);
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
    }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get Metamask!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const askContractToMintNft = async () => {
    const CONTRACT_ADDRESS = "0x22e454438722092d2Ce730a4f5Ed8d9C72B8c7d0";

    if (mintAmount === 0) {
      return;
    }

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, bbc.abi, signer);

        let gasLimit = VARIABLES.GAS_LIMIT;

        let nftTxn = await connectedContract.mint(mintAmount, { gasLimit:gasLimit });
        console.log(nftTxn);

        console.log("Mining");
        await nftTxn.wait();
      } else {
        console.log("Ethereum object does not exist.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setPrice = async () => {
    const variablesResponse = await fetch("/variables.json");
    let price = await variablesResponse.json();
    setCurrentGas(price);
  }

  // render connect button instead of mint button if not connected
  const renderNotConnectedContainer = () => (
    <div className="button-container">
      <button onClick={connectWallet} className="cta-button connect-wallet-button">
        Connect Wallet
      </button>
    </div>
  )

  const renderMintButton = () => (
    <>
      <div className="mint-amount-container">
        <button onClick={decrementCount} className="button count-button">-</button>
        <span className="count">{mintAmount}</span>
        <button onClick={incrementCount} className="button count-button">+</button>
      </div>
      <div className="mint-button-container">
        <button onClick={askContractToMintNft} className="button cta-button mint-button">
            CLAIM FREE BBC
        </button>
      </div>
    </>
  )

  // reduce count on button
  function decrementCount() {
    if (mintAmount > 0) {
      setCount(prevCount => prevCount - 1);
    }
  }

  // increase count on button
  function incrementCount() {
    if (mintAmount < 20) {
      setCount(prevCount => prevCount + 1);
    }
  }

  useEffect(() => {
    setPrice();
  }, []);

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <h1 className="header">BIG BRICK CLUB</h1>
          <div className="brick-left">
            <img src={ Dog } alt = "Dog Brick"/>
          </div>
          <div className="brick-right">
              
          </div>
          <h1 className='sold-out'>SOLD OUT</h1>
        </div>
      </div>
    </div>
  );
}

export default App;